<template>
  <section class="component--wrapper" style="max-width: 700px;">
    <el-row type="flex" align="middle">
      <el-col v-show="backButton" :xl="5" :lg="5" :md="5" :sm="6" :xs="6">
        <el-button type="danger" size="mini" @click="emitBackStep">Voltar</el-button>
      </el-col>
      <el-col
        :xl="backButton ? 19 : 24 "
        :lg="backButton ? 19 : 24"
        :md="backButton ? 19 : 24"
        :sm="backButton ? 18 : 24"
        :xs="backButton ? 18 : 24"
      >
        <el-progress :percentage="barStatus" :show-text="false"></el-progress>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  props: {
    barStatus: {
      type: Number,
      default: 0,
      required: true
    },
    backButton: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
    emitBackStep() {
      this.$emit("backStep");
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
}
</style>
