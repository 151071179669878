<template>
  <section class="component--wrapper">
    <el-row>
      <el-col :xl="24">
        <h1>
          <b>Queremos te conhecer melhor</b>
        </h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xl="8"
        :lg="8"
        :md="8"
        :sm="8"
        :xs="24"
        v-for="type in business"
        :key="type.id"
      >
        <div
          @click="handleActiveClass(type)"
          class="card-item"
          :class="{ cardItemActive: type.selected }"
        >
          <span>{{ type.name }}</span>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      business: [
        {
          id: 1,
          name: "Sou uma loja",
          selected: false
        },
        {
          id: 2,
          name: "Sou revendedor",
          selected: false
        },
        {
          id: 3,
          name: "Sou autônomo",
          selected: false
        }
      ]
    };
  },
  methods: {
    handleActiveClass(type) {
      this.business.map(item => (item.selected = false));
      this.emitSegmentEvent(type);
      return (type.selected = true);
    },
    emitSegmentEvent(type) {
      this.$emit("knowQuestion", type.name);
      this.$emit("segmentEvent");
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
  max-width: 700px;
}
.card-item {
  padding: 2rem 1rem;
  font-weight: bold;
  color: $laranja;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(108, 115, 124, 0.3);
  font-size: 1rem;
  cursor: pointer;
  margin: 0.3rem;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
  span {
    vertical-align: middle;
  }
}
.cardItemActive {
  box-shadow: 0;
  border: 2px solid $laranja;
}
</style>
