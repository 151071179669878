<template>
  <section class="component--wrapper">
    <el-row>
      <el-col :xl="24">
        <h1>
          <b>Qual o seu principal ramo de atividade ?</b>
        </h1>
      </el-col>
    </el-row>
    <!-- <el-row :gutter="10">
      <el-col v-for="type in types" :key="type.id" :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
        <div
          @click="
            handleActiveClass(type);
            emitHowManyEvent();
          "
          class="card-item"
          :class="{ cardItemActive: type.description }"
        >
          <span>{{ type.label }}</span>
        </div>
      </el-col>
    </el-row>-->
    <div class="cards--wrapper">
      <div
        v-for="categorie in categories"
        :key="categorie.id"
        @click="handleActiveClass(categorie)"
        class="card-item"
        :class="{ cardItemActive: categorie.selected }"
      >
        <p>{{ categorie.label }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  computed: {
    ...mapGetters({
      categories: types.STORE_CATEGORIES
    })
  },
  async mounted() {
    await this.$store.dispatch(types.STORE_CATEGORIES);
  },
  methods: {
    handleActiveClass(categorie) {
      this.emitHowManyEvent(categorie);
    },
    emitHowManyEvent(categorie) {
      this.$emit("segmentQuestion", categorie.id);
      this.$emit("howManyEvent");
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
  max-width: 700px;
}
.cards--wrapper {
  display: grid;
  align-content: center;
  justify-content: center;
  grid: auto / 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media screen and(max-width: 768px) {
    grid: auto / 1fr;
    align-content: center;
  }
  @media screen and(min-width: 769px) and (max-width: 991px) {
    grid: auto / 1fr 1fr 1fr;
  }
}
.card-item {
  @media screen and(min-width: 769px) {
    display: grid;
  }
  height: 100%;
  width: 100%;
  padding: 2rem 0.5rem;
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(108, 115, 124, 0.3);
  cursor: pointer;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
  p {
    color: $laranja;
    align-self: center;
    line-height: 100%;
  }
}
.cardItemActive {
  box-shadow: 0;
  border: 2px solid $laranja;
}
</style>
