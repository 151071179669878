<template>
  <sign-up-store />
</template>

<script>
import SignUpStore from "@/containers/SignUpStore/SignUpStore";
export default {
  components: {
    SignUpStore
  }
}
</script>

<style>

</style>
