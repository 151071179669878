<template>
  <section class="component--wrapper">
    <el-row>
      <el-col :xl="24">
        <h1>
          <b>Vamos criar a sua loja.</b>
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          <!-- </br> -->
        </h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xl="24">
        <el-form
          v-loading="submitLoading"
          ref="storeForm"
          :rules="rules"
          :model="storeForm"
          style="max-width: 700px; margin: 0 auto;"
        >
          <el-form-item v-if="!$isMobile" prop="cnpj">
            <el-input
              v-mask="'##.###.###/####-##'"
              label="12.123.456/0001-78"
              v-model="storeForm.cnpj"
            >
              <template slot="prepend">CNPJ:</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="cnpj" v-else>
            <el-input placeholder="CNPJ" v-model="storeForm.cnpj"></el-input>
          </el-form-item>
          <el-form-item prop="name" v-if="!$isMobile">
            <el-input :disabled="!continueForm" v-model="storeForm.name">
              <template slot="prepend">Nome da loja:</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="name" v-else>
            <el-input :disabled="!continueForm" placeholder="Nome da loja" v-model="storeForm.name"></el-input>
          </el-form-item>
          <el-form-item prop="email" v-if="!$isMobile">
            <el-input :disabled="!continueForm" type="email" v-model="storeForm.email">
              <template slot="prepend">E-mail da loja:</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="email" v-else>
            <el-input
              :disabled="!continueForm"
              placeholder="E-mail da loja"
              type="email"
              v-model="storeForm.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" v-if="!$isMobile">
            <el-input
              v-mask="['(##) #####-####', '(##) ####-####']"
              :disabled="!continueForm"
              v-model="storeForm.phone"
            >
              <template slot="prepend">Telefone da loja:</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone" v-else>
            <el-input
              v-mask="['(##) #####-####', '(##) ####-####']"
              :disabled="!continueForm"
              placeholder="Telefone da loja"
              v-model="storeForm.phone"
            ></el-input>
          </el-form-item>
          <el-form-item prop="postalCode" v-if="!$isMobile">
            <el-input
              @input="cepAutoComplete(storeForm.postalCode)"
              v-mask="'#####-###'"
              :disabled="!continueForm"
              v-model="storeForm.postalCode"
            >
              <template slot="prepend">CEP:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-else prop="postalCode">
            <el-input
              @input="cepAutoComplete(storeForm.postalCode)"
              v-mask="'#####-###'"
              :disabled="!continueForm"
              placeholder="CEP"
              v-model="storeForm.postalCode"
            ></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="street" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.street">
              <template slot="prepend">Logadouro:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="street">
            <el-input :disabled="!cepPromise" placeholder="Logadouro" v-model="storeForm.street"></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="number" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.number">
              <template slot="prepend">Número:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="number">
            <el-input :disabled="!cepPromise" placeholder="Número" v-model="storeForm.number"></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="complement" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.complement">
              <template slot="prepend">Complemento:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="complement">
            <el-input
              :disabled="!cepPromise"
              placeholder="Complemento"
              v-model="storeForm.complement"
            ></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="neighborhood" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.neighborhood">
              <template slot="prepend">Bairro:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="neighborhood">
            <el-input :disabled="!cepPromise" placeholder="Bairro" v-model="storeForm.neighborhood"></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="city" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.city">
              <template slot="prepend">Cidade:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="city">
            <el-input :disabled="!cepPromise" placeholder="Cidade" v-model="storeForm.city"></el-input>
          </el-form-item>
          <el-form-item v-loading="loading" prop="uf" v-if="!$isMobile">
            <el-input :disabled="!cepPromise" v-model="storeForm.uf">
              <template slot="prepend">UF:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-loading="loading" v-else prop="uf">
            <el-input :disabled="!cepPromise" placeholder="UF" v-model="storeForm.uf"></el-input>
          </el-form-item>
          <el-form-item class="terms-form-item" prop="terms">
            <el-checkbox-group v-model="storeForm.terms">
              <el-checkbox name="terms" :label="useTerms">
                <p class="checkbox-p">
                  Li e aceito os
                  <a
                    href="https://www.neopag.com/termos-de-uso-aplicativo"
                    target="_blank"
                  >Termos de uso</a>
                  e
                  <a
                    href="https://www.neopag.com/politica-de-privacidade"
                    target="_blank"
                  >Politica de privacidade</a>
                  do site.
                </p>
              </el-checkbox>
              <el-checkbox name="terms" :label="truePerson">
                <p class="checkbox-p">
                  Sou representante legal da loja cadastrada e tenho poderes
                  para contratar.
                </p>
              </el-checkbox>
              <el-checkbox name="terms" :label="contractTerms">
                <p class="checkbox-p">
                  Li e adiro aos
                  <a
                    href="https://www.neopag.com/contrato-loja"
                    target="_blank"
                  >Termos do contrato de prestação de serviços</a>.
                </p>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div style="display:flex; justify-content: center">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="$captcha"
              style="margin: 1rem 0;"
              @verify="handleCaptchaVerify"
              @expired="handleCaptchaExpire"
            />
          </div>
          <el-button type="success" @click="handleSubmit('storeForm')">Finalizar Cadastro</el-button>
        </el-form>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import isCNPJ from "@/helpers/isCNPJ";
import cep from "cep-promise";
export default {
  components: {
    VueRecaptcha
  },
  props: {
    knowAnswer: {
      type: String,
      default: ""
    },
    segmentAnswer: {
      type: String,
      default: ""
    },
    howManyAnswer: {
      type: String,
      default: ""
    },
    howMuchAnswer: {
      type: String,
      default: ""
    },
    weCanHelpAnswer: {
      type: String,
      deafult: ""
    }
  },
  data() {
    const checkCNPJ = (rule, value, callback) => {
      const formatedCNPJ = isCNPJ(value.replace(/[.-/]/g, ""));
      const cleanCNPJ = value.replace(/[./-]/g, "");
      if (!formatedCNPJ) {
        this.continueForm = false;
        return callback(new Error("Por favor insira um CNPJ válido"));
      } else {
        callback();
        this.continueForm = true;
      }
    };
    const checkEmail = async (rule, value, callback) => {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return callback(new Error("Por favor insira um email válido"));
      } else {
        try {
          await this.resetEmailGetter();
          await this.checkEmailAlreadyExist(value);
          if (!this.user.registered_in_app) {
            callback();
          } else {
            return callback();
          }
        } catch (error) {}
      }
    };
    const checkNumber = async (rule, value, callback) => {
      const cleanNumber = value.replace(/[()-\s]/g, "");
      if (cleanNumber.length < 10) {
        return callback(new Error("Insira um número de celular válido."));
      } else {
        callback();
      }
    };
    const checkCEP = async (rule, value, callback) => {
      const cleanCEP = value.replace(/[-]/g, "");
      if (cleanCEP.length === 8) {
        callback();
      } else {
        await this.$store.dispatch(types.RESET_LAT_LNG, null);
        this.loading = false;
        callback(new Error("Digite um CEP válido"));
      }
    };
    const checkTerms = (rule, value, callback) => {
      if (value.length === 3) {
        callback();
      } else {
        callback(new Error("Você deve concordar com todos os termos"));
      }
    };
    const checkStreet = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Você deve preencher esse campo."));
      } else {
        callback();
      }
    };
    const checkNeighborhood = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Você deve preencher esse campo."));
      } else {
        callback();
      }
    };
    const checkCity = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Você deve preencher esse campo."));
      } else {
        callback();
      }
    };
    const checkUf = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Você deve preencher esse campo."));
      } else {
        callback();
      }
    };
    return {
      useTerms: "useTerms",
      truePerson: "truePerson",
      contractTerms: "contractTerms",
      continueForm: false,
      cepPromise: null,
      loading: false,
      submitLoading: false,
      captched: false,
      storeForm: {
        cnpj: "",
        name: "",
        email: "",
        phone: "",
        postalCode: "",
        street: "",
        number: null,
        complement: "",
        neighborhood: "",
        city: "",
        uf: "",
        terms: []
      },
      rules: {
        cnpj: [
          {
            required: true,
            trigger: "change",
            validator: checkCNPJ
          }
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "Você deve dar um nome a sua loja"
          }
        ],
        email: [
          {
            required: true,
            trigger: "change",
            validator: checkEmail
          }
        ],
        phone: [
          {
            required: true,
            trigger: "change",
            validator: checkNumber
          }
        ],
        postalCode: [
          {
            required: true,
            trigger: "change",
            validator: checkCEP
          }
        ],
        terms: [
          {
            type: "array",
            required: true,
            trigger: "change",
            validator: checkTerms
          }
        ],
        number: [
          {
            required: true,
            trigger: "blur",
            message: "O número é obrigatório"
          }
        ],
        street: [
          {
            required: true,
            trigger: "change",
            validator: checkStreet
          }
        ],
        neighborhood: [
          {
            required: true,
            trigger: "change",
            validator: checkNeighborhood
          }
        ],
        city: [
          {
            required: true,
            trigger: "change",
            validator: checkCity
          }
        ],
        uf: [
          {
            required: true,
            trigger: "change",
            validator: checkUf
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      user: types.AUTH_USER,
      userByCpf: types.USER_INFO_BY_CPF,
      location: types.LAT_LNG
    })
  },
  methods: {
    handleSubmit(form) {
      this.$refs[form].validate(async valid => {
        if (valid && this.captched) {
          const payload = {
            store: {
              name: this.storeForm.name,
              email: this.storeForm.email,
              cnpj: this.storeForm.cnpj.replace(/[./-]/g, ""),
              phone: `+55${this.storeForm.phone.replace(/[()-\s]/g, "")}`,
              category_id: this.segmentAnswer,
              onboardin_questions: {
                type: this.knowAnswer,
                how_many: this.howManyAnswer,
                how_much: this.howMuchAnswer,
                help: this.weCanHelpAnswer
              },
              address: {
                street: this.storeForm.street,
                number: this.storeForm.number,
                city: this.storeForm.city,
                uf: this.storeForm.uf,
                cep: this.storeForm.postalCode,
                complement: this.storeForm.complement,
                latitude: this.location?.lat,
                longitude: this.location?.lng
              }
            },
            user_id: this.user.id
          };
          try {
            await this.$store.dispatch(types.STORE_CREATE, {
              payload
            });
            // if (!this.userByCpf.phone_confirmed) {
            //   this.$emit("confirmPhoneEvent");
            // }
            this.$emit("finalStepEvent");
            this.$notify.success("Loja criada com sucesso.");
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          return false;
        }
      });
      this.submitLoading = false;
    },
    async handleCaptchaVerify(response) {
      try {
        this.$store
          .dispatch(types.AUTH_VERIFY_CAPTCHA, response)
          .then(() => {
            this.captched = true;
          })
          .catch(error => {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          });
      } catch {
        this.$notify.error({
          message: "Ops, erro no captcha. Tente novamente"
        });
        this.$refs.recaptcha.reset();
        this.captched = false;
      }
    },
    handleCaptchaExpire() {
      this.$refs.recaptcha.reset();
      this.captched = false;
    },
    async cepAutoComplete(value) {
      const cleanCEP = value.replace(/[-]/g, "");
      if (cleanCEP.length === 8) {
        this.loading = true;
        try {
          this.cepPromise = await cep(cleanCEP);
          this.storeForm.street = this.cepPromise.street;
          this.storeForm.city = this.cepPromise.city;
          this.storeForm.uf = this.cepPromise.state;
          this.storeForm.neighborhood = this.cepPromise.neighborhood;
          await this.$store.dispatch(types.GET_LAT_LNG, {
            cep: cleanCEP,
            uglyCep: value
          });
          this.loading = false;
        } catch (error) {
          await this.$store.dispatch(types.RESET_LAT_LNG, null);
          this.$notify.error(
            "Ocorreu um erro, por favor digite o CEP novamente."
          );
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  max-width: 700px;
  a {
    color: $laranja;
    text-decoration: none;
  }
  .el-form-item__content {
    label {
      .el-checkbox__label {
        p {
          @media screen and(max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .btn--wrapper {
    margin-left: 1rem;
  }
  .terms-form-item {
    margin: 0.2rem !important;
    text-align: start;
  }
}
</style>
