<template>
  <section class="component--wrapper">
    <!-- <el-row>
      <div class="credit-card--wrapper">
        <div class="credit-card" :class="{cardFront: isActive}">
          <div class="credit-card--chip"></div>
          <div class="credit-card--number">
            <span>1234</span>
            <span>5678</span>
            <span>9101</span>
            <span>1121</span>
          </div>
          <div class="credit-card--expiration-date">
            <span>01/01</span>
          </div>
          <div class="credit-card--name">
            <span>Fulano de Tal</span>
          </div>
          <div class="credit-card--flag"></div>
        </div>
        <div class="credit-card-back" :class="{cardBack: !isActive}">
          <div class="credit-card-back--chip"></div>
          <div class="credit-card-back--number">
            <span>1234</span>
            <span>5678</span>
            <span>9101</span>
            <span>1121</span>
          </div>
          <div class="credit-card-back--expiration-date">
            <span>01/01</span>
          </div>
          <div class="credit-card-back--name">
            <span>Fulano de Tal</span>
          </div>
          <div class="credit-card-back--flag"></div>
        </div>
      </div>
    </el-row>-->
    <el-row>
      <el-col>
        <Lottie
          :options="defaultOptions"
          :height="$isMobile ? 100 : 200"
          :width="$isMobile ? 100 : 200"
          v-on:animCreated="handleAnimation"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h1>
          <b>Parabéns, agora você pode começar a utilizar nossa plataforma.</b>
        </h1>
      </el-col>
    </el-row>
    <el-row>
      <!-- <el-button @click="rotateCard">Rodar Cartão</el-button> -->
      <div class="button--wrapper">
        <el-button type="success" @click="handleLogin">Começar a utilizar</el-button>
      </div>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/check.json";
export default {
  components: {
    Lottie
  },
  data() {
    return {
      // isActive: false
      defaultOptions: {
        animationData: animationData.default,
        path: "../../assets/check.json",
        loop: false,
        autoplay: true,
        render: "svg"
      },
      animationSpeed: 1
    };
  },
  computed: {
    ...mapGetters({
      user: types.USER_INFO_BY_EMAIL
    })
  },
  methods: {
    async handleLogin() {
      try {
        await this.$store.dispatch(types.AUTH_CAMES_FROM_SIGNUP, {
          step: "store"
        });
      } catch (error) {
        this.$notify.error({ title: error.message });
      }
      this.$router.push({ name: "Login" });
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    }
    // rotateCard() {
    //   this.isActive = !this.isActive;
    // }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  max-width: 700px;
  margin: 1rem auto 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
}
.button--wrapper {
  display: flex;
  justify-content: center;
}
// .cardFront {
//   opacity: 0;
//   position: absolute !important;
//   left: 0 !important;
//   top: 0 !important;
// }
// .cardBack {
//   position: absolute !important;
//   left: 0 !important;
//   top: 0 !important;
//   opacity: 0;
// }
// .credit-card--wrapper {
//   display: flex;
//   justify-content: center;
// }
// .credit-card {
//   position: relative;
//   border-radius: 10px;
//   height: 250px;
//   width: 400px;
//   background-color: $laranja;
//   color: #ffffff;
//   &--chip {
//     position: absolute;
//     width: 50px;
//     height: 30px;
//     left: 30px;
//     top: 80px;
//     border-radius: 10px;
//     background-color: yellow;
//   }
//   &--number {
//     display: flex;
//     justify-content: space-evenly;
//     font-size: 2rem;
//     position: absolute;
//     width: 350px;
//     height: 40px;
//     background-color: green;
//     top: 130px;
//     left: 25px;
//   }
//   &--expiration-date {
//     font-weight: bold;
//     position: absolute;
//     background-color: blue;
//     width: 45px;
//     height: 20px;
//     top: 180px;
//     left: 150px;
//   }
//   &--name {
//     font-size: 1.3rem;
//     font-weight: bold;
//     position: absolute;
//     background-color: black;
//     width: 150px;
//     height: 30px;
//     top: 210px;
//     left: 25px;
//   }
//   &--flag {
//   }
// }
// .credit-card-back {
//   position: relative;
//   border-radius: 10px;
//   height: 250px;
//   width: 400px;
//   background-color: $laranja;
//   color: #ffffff;
//   &--chip {
//     position: absolute;
//     width: 50px;
//     height: 30px;
//     left: 30px;
//     top: 80px;
//     border-radius: 10px;
//     background-color: red;
//   }
//   &--number {
//     display: flex;
//     justify-content: space-evenly;
//     font-size: 2rem;
//     position: absolute;
//     width: 350px;
//     height: 40px;
//     background-color: black;
//     top: 130px;
//     left: 25px;
//   }
//   &--expiration-date {
//     font-weight: bold;
//     position: absolute;
//     background-color: white;
//     width: 45px;
//     height: 20px;
//     top: 180px;
//     left: 150px;
//   }
//   &--name {
//     font-size: 1.3rem;
//     font-weight: bold;
//     position: absolute;
//     background-color: brown;
//     width: 150px;
//     height: 30px;
//     top: 210px;
//     left: 25px;
//   }
//   &--flag {
//   }
// }
</style>
