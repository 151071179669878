<template>
  <section class="component--wrapper">
    <el-row style="height:65.47px;">
      <el-col :xl="24" :xs="24">
        <h1>
          <b>Vamos criar seu usuário.</b>
        </h1>
      </el-col>
      <el-col :xl="24">
        <p class="typewriter" v-if="login">Identificamos que você já possui uma conta.</p>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :xs="24" :xl="24">
        <el-form
          v-loading="loading"
          @submit.native.prevent
          :model="userForm"
          ref="userForm"
          :rules="rules"
          style="max-width: 700px; margin: 0 auto;"
        >
          <el-form-item prop="cpf" v-if="!$isMobile">
            <el-input v-mask="'###.###.###-##'" label="123.456.789-00" v-model="userForm.cpf">
              <template slot="prepend">CPF:</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="cpf" v-else-if="$isMobile">
            <el-input placeholder="CPF" v-mask="'###.###.###-##'" v-model="userForm.cpf"></el-input>
          </el-form-item>
          <transition name="password-fade">
            <el-form-item v-if="!$isMobile && login" prop="passwordLogin">
              <el-input
                :disabled="!continueForm && !login"
                v-model="userForm.passwordLogin"
                type="password"
                :show-password="false"
              >
                <template slot="prepend">Senha:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && login" prop="passwordLogin">
              <el-input
                :disabled="!continueForm && !login"
                v-model="userForm.passwordLogin"
                :show-password="false"
                type="password"
                placeholder="Senha"
              ></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!$isMobile && !login" prop="mobile">
              <el-input
                v-mask="'(##) #####-####'"
                :disabled="!continueForm"
                v-model="userForm.mobile"
                name="mobile"
              >
                <template slot="prepend">Celular:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && !login" prop="mobile">
              <el-input
                v-mask="'(##) #####-####'"
                :disabled="!continueForm"
                placeholder="Celular"
                name="mobile"
                v-model="userForm.mobile"
              ></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!$isMobile && !login">
              <el-input :disabled="true" type="email" :value="userForm.email">
                <template slot="prepend">E-mail:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && !login">
              <el-input :disabled="true" type="email" :value="userForm.email"></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!$isMobile && !login" prop="name">
              <el-input :disabled="!continueForm" v-model="userForm.name">
                <template slot="prepend">Nome Completo:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && !login" prop="name">
              <el-input
                :disabled="!continueForm"
                placeholder="Nome completo"
                v-model="userForm.name"
              ></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!$isMobile && !login" prop="password">
              <el-input
                :disabled="!continueForm && !login"
                v-model="userForm.password"
                type="password"
                :show-password="false"
              >
                <template slot="prepend">Senha:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && !login" prop="password">
              <el-input
                :disabled="!continueForm && !login"
                v-model="userForm.password"
                :show-password="false"
                type="password"
                placeholder="Senha"
              ></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!$isMobile && !login" prop="passwordConfirm">
              <el-input
                :disabled="!continueForm"
                v-model="userForm.passwordConfirm"
                :show-password="false"
                type="password"
              >
                <template slot="prepend">Confirmar senha:</template>
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="$isMobile && !login" prop="passwordConfirm">
              <el-input
                :disabled="!continueForm"
                v-model="userForm.passwordConfirm"
                :show-password="false"
                type="password"
                placeholder="Confirmar senha"
              ></el-input>
            </el-form-item>
          </transition>
          <transition name="slide-fade">
            <el-form-item v-if="!login" prop="terms">
              <el-checkbox :disabled="!continueForm" :label="useTerms" v-model="userForm.terms">
                <p>
                  Li e aceito os
                  <a
                    href="https://www.neopag.com/termos-de-uso-aplicativo"
                    target="_blank"
                  >Termos de uso</a>
                  e
                  <a
                    href="https://www.neopag.com/politica-de-privacidade"
                    target="_blank"
                  >Politica de privacidade</a>
                  do site.
                </p>
              </el-checkbox>
            </el-form-item>
          </transition>
          <div style="display:flex; justify-content: center">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="$captcha"
              style="margin: 1rem 0;"
              @verify="handleCaptchaVerify"
              @expired="handleCaptchaExpire"
            />
          </div>
          <el-button
            v-loading="loading"
            v-if="!login"
            type="success"
            class="btn-wrapper"
            :disabled="!continueForm && !captched"
            @click="handleSubmit('userForm')"
          >Próxima etapa</el-button>
          <el-button
            v-loading="loading"
            v-else
            type="danger"
            class="btn-wrapper"
            @click="handleLogin"
          >Autenticar</el-button>
        </el-form>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import isCPF from "@/helpers/isCPF";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
export default {
  components: {
    VueRecaptcha
  },
  props: {
    userEmail: {
      type: String,
      default: ""
    }
  },
  data() {
    const checkCPF = async (rule, value, callback) => {
      const formatedCPF = isCPF(value.replace(/[.-]/g, ""));
      const cleanCPF = value.replace(/\D/g, "");
      if (!formatedCPF) {
        this.continueForm = false;
        this.login = false;
        return callback(new Error("Por favor insira um CPF válido"));
      } else {
        try {
          this.loading = true;
          await this.checkUserAlreadyExist(cleanCPF);
          if (!this.userByCpf.registered) {
            this.login = false;
            this.continueForm = true;
            callback();
          } else {
            this.login = true;
            callback();
          }
        } catch (error) {
          this.$notify.error({ title: error.message });
        }
        this.loading = false;
      }
    };
    const checkNumber = async (rule, value, callback) => {
      const cleanNumber = value.replace(/[()-\s]/g, "");
      if (cleanNumber.length < 11) {
        return callback(new Error("Insira um número de celular válido."));
      } else {
        callback();
      }
    };
    const checkPassWord = async (rule, value, callback) => {
      if (value.length < 8) {
        return callback(new Error("A senha deve conter no mínimo 8 digitos."));
      } else {
        callback();
      }
    };
    const checkPasswordConfirm = (rule, value, callback) => {
      if (this.userForm.password === value) {
        callback();
      } else {
        return callback(new Error("As senhas devem ser iguais."));
      }
    };
    const checkBox = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(
          new Error("Você deve concordar com os termos antes de prosseguir")
        );
      }
    };
    return {
      loading: false,
      useTerms: "useTerms",
      continueForm: false,
      login: false,
      submit: false,
      captched: false,
      userForm: {
        cpf: "",
        mobile: "",
        email: this.userEmail,
        name: "",
        password: "",
        passwordConfirm: "",
        passwordLogin: "",
        terms: false
      },
      rules: {
        cpf: [
          {
            required: true,
            trigger: "change",
            validator: checkCPF
          }
        ],
        mobile: [
          {
            required: true,
            trigger: "change",
            validator: checkNumber
          }
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "O nome é obrigatório"
          }
        ],
        password: [
          {
            required: true,
            trigger: "change",
            min: 8,
            validator: checkPassWord
          }
        ],
        passwordLogin: [
          {
            required: true,
            trigger: "blur",
            message: "Esse campo é obrigatório"
          }
        ],
        passwordConfirm: [
          {
            required: true,
            validator: checkPasswordConfirm,
            trigger: "change"
          }
        ],
        terms: [
          {
            required: true,
            trigger: "change",
            validator: checkBox
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      userByCpf: types.USER_INFO_BY_CPF,
      user: types.AUTH_USER,
      userCreatePasswordResponse: types.USER_CREATE_PASSWORD
    })
  },
  methods: {
    async checkUserAlreadyExist(value) {
      try {
        await this.$store.dispatch(types.USER_INFO_BY_CPF, { cpf: value });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleLogin() {
      const data = {
        email: this.userForm.email,
        password: this.userForm.passwordLogin
      };
      try {
        await this.$store.dispatch(types.AUTH_LOGIN, data);
        this.$notify.success("Autenticado com sucesso !");
        this.$emit("knowEvent");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$store.dispatch(types.AUTH_LOGOUT);
        this.$notify.error({ title: error.message });
      }
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const userID = this.userByCpf.id;
            const payload = {
              user: {
                password: this.userForm.password
              }
            };
            await this.$store.dispatch(types.USER_CREATE_PASSWORD, {
              userID,
              payload
            });
            payload.user = {
              email: this.userForm.email,
              name: this.userForm.name,
              phone: `55${this.userForm.mobile.replace(/[()-\s]/g, "")}`,
              cpf: this.userForm.cpf.replace(/\D/g, ""),
              status_id: 1
            };
            await this.$store.dispatch(types.USER_CREATE, {
              userID,
              payload
            });
            this.$notify.success("Usuário criado com sucesso.");
            this.$emit("storeAccountEvent");
            if (this.userCreatePasswordResponse) {
              this.$emit("createAccountStepBackDisable");
            }
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          this.$notify.warning({ title: "Verique os dados informados." });
        }
      });
    },
    async handleCaptchaVerify(response) {
      try {
        this.$store
          .dispatch(types.AUTH_VERIFY_CAPTCHA, response)
          .then(() => {
            this.captched = true;
          })
          .catch(error => {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          });
      } catch {
        this.$notify.error({
          message: "Ops, erro no captcha. Tente novamente"
        });
        this.$refs.recaptcha.reset();
        this.captched = false;
      }
    },
    handleCaptchaExpire() {
      this.$refs.recaptcha.reset();
      this.captched = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  max-width: 700px;
}
.el-form-item__content {
  label {
    .el-checkbox__label {
      p {
        @media screen and(max-width: 767px) {
          font-size: 0.7rem;
        }
      }
    }
  }
}
a {
  color: $laranja !important;
  text-decoration: none;
}
.typewriter {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 1.5s steps(40, end);
  @media screen and(max-width: 767px) {
    font-size: 0.7rem;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-fade-leave-to {
  transform: translateX(30%);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(-30%);
  opacity: 0;
}
.password-fade-enter-active {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.2s;
}
.password-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.password-fade-leave-to {
  transform: translateY(30%);
  opacity: 0;
}
.password-fade-enter {
  transform: translateY(-30%);
  opacity: 0;
}
</style>
