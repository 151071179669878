<template>
  <section class="component--wrapper">
    <el-row>
      <el-col :xl="24">
        <h1>
          <b>Quantas lojas você possui ?</b>
        </h1>
      </el-col>
    </el-row>
    <div class="cards--wrapper">
      <div
        v-for="quantity in quantities"
        :key="quantity.id"
        @click="handleActiveClass(quantity)"
        class="card-item"
        :class="{ cardItemActive: quantity.selected }"
      >
        <p>{{ quantity.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      quantities: [
        {
          id: 1,
          text: "Apenas uma loja",
          selected: false
        },
        {
          id: 2,
          text: "De 2 a 5 lojas",
          selected: false
        },
        {
          id: 3,
          text: "Mais de 5 lojas",
          selected: false
        },
        {
          id: 4,
          text: "Não tenho loja física",
          selected: false
        }
      ]
    };
  },
  methods: {
    handleActiveClass(quantity) {
      this.quantities.map(item => (item.selected = false));
      this.emitHowMuchEvent(quantity);
      return (quantity.selected = true);
    },
    emitHowMuchEvent(quantity) {
      this.$emit("howManyQuestion", quantity.text);
      this.$emit("howMuchEvent");
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem auto 0 auto;
  text-align: center;
  max-width: 700px;
}
.cards--wrapper {
  display: grid;
  @media screen and(max-width: 768px) {
    grid: auto / 1fr;
  }
  @media screen and(min-width: 769px) and (max-width: 991px) {
    grid: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr;
  }
  grid: 1fr / 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  align-content: center;
  justify-content: center;
}
.card-item {
  @media screen and(min-width: 769px) {
    display: grid;
  }
  height: 100%;
  width: 100%;
  padding: 2rem 0.5rem;
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(108, 115, 124, 0.3);
  cursor: pointer;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
  p {
    color: $laranja;
    align-self: center;
    line-height: 100%;
  }
}
.cardItemActive {
  box-shadow: 0;
  border: 2px solid $laranja;
}
</style>
