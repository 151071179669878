<template>
  <section class="container--wrapper">
    <el-row class="logo">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="subscription-overview--logo">
        <div class="container-header">
          <div class="custom-flex">
            <Logo :alternative="false" width="161" />
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="container">
      <progress-bar
        :back-button="this.backButton"
        @backStep="handleBackStep"
        :bar-status="barPorcentage"
      />
      <info-text v-if="!finalStepToggle" />
      <transition :name="transitionStyle()">
        <email-step
          @userEmailEvent="userEmailSignUpMethod"
          @knowEvent="handleKnowStep"
          v-show="emailStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <know-step
          @segmentEvent="handleSegmentStep"
          @knowQuestion="sendKnowAnswer"
          v-if="knowStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <segment-step
          @segmentQuestion="sendSegmentAnswer"
          @howManyEvent="handleHowManyStep"
          v-if="segmentStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <how-many-step
          @howManyQuestion="sendHowManyAnswer"
          @howMuchEvent="handleHowMuchStep"
          v-if="howManyStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <how-much-step
          @howMuchQuestion="sendHowMuchAnswer"
          @canWeHelpEvent="handleCanWeHelpStep"
          v-if="howMuchStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <can-we-help-step
          @canWeHelpQuestion="sendCanWeHelpAnswer"
          @userAccountEvent="handleUserAccountStep"
          v-if="canWeHelpStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <account-step
          :user-email="userEmailSignUp"
          v-if="userAccountStepToggle"
          @storeAccountEvent="handleStoreAccountStep"
          @createAccountStepBackDisable="backButtonDisable"
        />
      </transition>
      <transition :name="transitionStyle()">
        <store-step
          :know-answer="answer1"
          :segment-answer="category_id"
          :howMany-answer="answer3"
          :howMuch-answer="answer4"
          :we-can-help-answer="answer5"
          @confirmPhoneEvent="confirmPhoneFunction"
          @finalStepEvent="handleFinalStep"
          v-if="storeAccountStepToggle"
        />
      </transition>
      <transition :name="transitionStyle()">
        <final-step v-if="finalStepToggle" />
      </transition>
    </div>
    <confirm-phone-dialog
      v-if="confirmPhoneStep"
      @closeDialogPhone="confirmPhoneStep = false"
      :show-dialog="confirmPhoneStep"
    />
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import Logo from "@/components/Logo";
import ProgressBar from "@/components/SignUpStore/ProgressBar";
import InfoText from "@/components/SignUpStore/InfoText";
import EmailStep from "@/components/SignUpStore/EmailStep";
import KnowStep from "@/components/SignUpStore/KnowStep";
import SegmentStep from "@/components/SignUpStore/SegmentStep";
import HowManyStep from "@/components/SignUpStore/HowManyStep";
import HowMuchStep from "@/components/SignUpStore/HowMuchStep";
import CanWeHelpStep from "@/components/SignUpStore/CanWeHelpStep";
import AccountStep from "@/components/SignUpStore/AccountStep";
import StoreStep from "@/components/SignUpStore/StoreStep";
import FinalStep from "@/components/SignUpStore/FinalStep";
import ConfirmPhoneDialog from "@/components/SignUpStore/ConfirmPhoneDialog";
export default {
  components: {
    Logo,
    ProgressBar,
    InfoText,
    EmailStep,
    KnowStep,
    SegmentStep,
    HowManyStep,
    HowMuchStep,
    CanWeHelpStep,
    AccountStep,
    StoreStep,
    FinalStep,
    ConfirmPhoneDialog
  },
  computed: {
    ...mapGetters({
      user: types.AUTH_USER
    })
  },
  data() {
    return {
      barPorcentage: 0,
      transitionBack: false,
      emailStepToggle: true,
      backButton: false,
      knowStepToggle: false,
      segmentStepToggle: false,
      howManyStepToggle: false,
      howMuchStepToggle: false,
      canWeHelpStepToggle: false,
      userAccountStepToggle: false,
      storeAccountStepToggle: false,
      finalStepToggle: false,
      userEmailSignUp: "",
      answer1: "",
      category_id: "",
      answer3: "",
      answer4: "",
      answer5: "",
      confirmPhoneStep: false
    };
  },
  async created() {
    if (this.user) {
      this.knowStepToggle = true;
      this.emailStepToggle = false;
    } else {
      await this.$store.dispatch(types.AUTH_LOGOUT);
    }
  },
  methods: {
    handleBackStep() {
      this.transitionBack = true;
      if (this.knowStepToggle) {
        this.barPorcentage = 0;
        if (this.user) {
          this.emailStepToggle = false;
          this.backButton = false;
        } else {
          this.knowStepToggle = false;
          this.emailStepToggle = true;
          this.backButton = true;
        }
      } else if (this.segmentStepToggle) {
        this.barPorcentage = 12.5;
        this.segmentStepToggle = false;
        this.knowStepToggle = true;
        if (this.user) {
          this.backButton = false;
        }
      } else if (this.howManyStepToggle) {
        this.barPorcentage = 25;
        this.howManyStepToggle = false;
        this.segmentStepToggle = true;
      } else if (this.howMuchStepToggle) {
        this.barPorcentage = 37.5;
        this.howMuchStepToggle = false;
        this.howManyStepToggle = true;
      } else if (this.canWeHelpStepToggle) {
        this.barPorcentage = 50;
        this.canWeHelpStepToggle = false;
        this.howMuchStepToggle = true;
      } else if (this.userAccountStepToggle) {
        this.barPorcentage = 62.5;
        this.userAccountStepToggle = false;
        this.canWeHelpStepToggle = true;
      } else if (this.storeAccountStepToggle) {
        this.storeAccountStepToggle = false;
        if (this.user) {
          this.barPorcentage = 75;
          this.canWeHelpStepToggle = true;
        } else {
          this.userAccountStepToggle = true;
          this.barPorcentage = 62.5;
        }
      }
    },
    handleKnowStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 12.5;
      this.emailStepToggle = false;
      if (this.user) {
        this.backButton = false;
      } else {
        this.backButton = true;
      }
      this.knowStepToggle = true;
    },
    handleSegmentStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 25;
      this.knowStepToggle = false;
      this.segmentStepToggle = true;
      this.backButton = true;
    },
    handleHowManyStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 37.5;
      this.segmentStepToggle = false;
      this.howManyStepToggle = true;
    },
    handleHowMuchStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 50;
      this.howManyStepToggle = false;
      this.howMuchStepToggle = true;
    },
    handleCanWeHelpStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 62.5;
      this.howMuchStepToggle = false;
      this.canWeHelpStepToggle = true;
    },
    handleUserAccountStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.canWeHelpStepToggle = false;
      if (this.user) {
        this.storeAccountStepToggle = true;
        this.barPorcentage = 87.5;
      } else {
        this.userAccountStepToggle = true;
        this.barPorcentage = 75;
      }
    },
    handleStoreAccountStep() {
      this.transitionBack = false;
      this.transitionStyle;
      this.barPorcentage = 87.5;
      this.userAccountStepToggle = false;
      this.storeAccountStepToggle = true;
    },
    handleFinalStep() {
      this.transitionBack = false;
      this.backButton = false;
      this.transitionStyle;
      this.barPorcentage = 100;
      this.storeAccountStepToggle = false;
      this.finalStepToggle = true;
    },
    transitionStyle() {
      if (this.transitionBack) {
        return "slide-fade-back";
      } else {
        return "slide-fade";
      }
    },
    userEmailSignUpMethod(email) {
      this.userEmailSignUp = email;
    },
    sendKnowAnswer(answer) {
      this.answer1 = answer;
    },
    sendSegmentAnswer(id) {
      this.category_id = id;
    },
    sendHowManyAnswer(answer) {
      this.answer3 = answer;
    },
    sendHowMuchAnswer(answer) {
      this.answer4 = answer;
    },
    sendCanWeHelpAnswer(answer) {
      this.answer5 = answer;
    },
    confirmPhoneFunction() {
      this.confirmPhoneStep = true;
    },
    backButtonDisable() {
      this.backButton = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.container--wrapper {
  overflow-x: hidden;
}
.logo {
  background-color: $preto;
  .custom-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.container-header {
  max-width: 1280px;
  margin: 0 auto;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
  @media screen and(max-width: 767px) {
    padding: 0.5rem;
  }
  @media screen and(min-width: 768px) {
  }
  @media screen and(min-width: 992px) {
  }
  @media screen and(min-width: 1200px) {
  }
  @media screen and(min-width: 1920px) {
  }
}
.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-fade-leave-to {
  transform: translateX(30%);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(-30%);
  opacity: 0;
}

.slide-fade-back-enter-active {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.slide-fade-back-leave-active {
  display: none;
}
.slide-fade-back-enter {
  transform: translateX(30%);
  opacity: 0;
}
</style>
