<template>
  <section class="component--wrapper">
    <el-row>
      <el-col>
        <h1>{{textStep}}</h1>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      textStep: "Vamos começar as etapas para criar sua conta Neopag."
    };
  },
  methods: {
    handleText() {
      this.textStep = "Vamos começar as etapas para criar sua conta Neopag.";
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  margin: 1rem 0;
  text-align: center;
  h1 {
    color: $laranja;
    @media screen and(max-width: 767px) {
      font-size: 1rem;
    }
    @media screen and(min-width: 768px) {
      font-size: 1.2rem;
    }
    @media screen and(min-width: 992px) {
      font-size: 1.7rem;
    }
    @media screen and(min-width: 1200px) {
      font-size: 2.3rem;
    }
    @media screen and(min-width: 1920px) {
      font-size: 2.3rem;
    }
    font-weight: bold;
  }
}
</style>
