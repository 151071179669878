<template>
  <el-dialog
    title="Confirmar número de celular agora ?"
    :visible.sync="showDialog"
    :fullscreen="$isMobile"
    :width="$isMobile ? '90%' : '30%'"
    center
    :close-on-click-modal="false"
  >
    <span>
      Você pode confirmar seu número depois na plataforma caso não queira
      confirmar agora.
    </span>
    <el-form style="margin-top: 1rem;">
      <el-row :model="mobileForm" :gutter="10">
        <el-col :xl="16" :lg="16" :md="16" :sm="16">
          <el-form-item>
            <el-input :disabled="true" v-model="mobileForm.number"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-if="tokenIsGenerated"
              placeholder="Digite o token"
              v-model="mobileForm.token"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xl="8" :lg="8" :md="8" :sm="8">
          <el-button :disabled="tokenIsGenerated" type="success" @click="generateToken">Enviar token</el-button>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="emitCloseDialog">Depois</el-button>
      <el-button
        :disabled="!tokenIsGenerated"
        type="success"
        @click="handlePhoneValidation"
      >Confirmar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobileForm: {
        number: "",
        token: null
      },
      tokenIsGenerated: false
    };
  },
  computed: {
    ...mapGetters({
      userCreated: types.USER_CREATE,
      user: types.AUTH_USER
    })
  },
  mounted() {
    this.mobileForm.number = this.user.phone
      ? this.user.phone
      : this.userCreated.phone;
  },
  methods: {
    emitCloseDialog() {
      this.$emit("closeDialogPhone");
    },
    async generateToken() {
      this.tokenIsGenerated = true;
      try {
        await this.$store.dispatch(types.USER_CONFIRM_PHONE, {
          phone: this.mobileForm.number.replace(/[+()-\s]/g, ""),
          userID: this.userCreated.id ? this.userCreated.id : this.user.id
        });
        this.$notify.success({ title: "Código enviado!" });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handlePhoneValidation() {
      try {
        await this.$store.dispatch(types.USER_PHONE_TOKEN, {
          tokenCode: this.mobileForm.token,
          userID: this.userCreated.id ? this.userCreated.id : this.user.id
        });
        this.$notify.success("Número de telefone validado!");
        this.$emit("closeDialogPhone");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: "Token inválido" });
      }
    }
  }
};
</script>

<style></style>
