<template>
  <section class="component--wrapper">
    <el-row style="height:65.47px;">
      <el-col :xl="24" :xs="24">
        <h1>
          <b>Qual o seu Email ?</b>
        </h1>
      </el-col>
      <el-col :xl="24">
        <p class="typewriter" v-if="login">Identificamos que você já possui uma conta.</p>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="input--wrapper">
      <el-col :xl="18" :lg="18" :md="18" :sm="18" :xs="24">
        <el-form @submit.native.prevent :model="emailForm" ref="emailForm" :rules="rules">
          <el-form-item v-if="!$isMobile" prop="email">
            <el-input type="email" v-model="emailForm.email" style="max-width: 500px;">
              <template slot="prepend">E-mail:</template>
            </el-input>
          </el-form-item>
          <el-form-item v-else prop="email">
            <el-input
              type="email"
              v-model="emailForm.email"
              style="max-width: 500px;"
              placeholder="E-mail"
            ></el-input>
          </el-form-item>
          <transition name="login-fade">
            <el-form-item v-if="!$isMobile && login" prop="password">
              <el-input v-model="emailForm.password" :show-password="false" type="password">
                <template slot="prepend">Senha:</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" v-else-if="$isMobile && login">
              <el-input
                v-model="emailForm.password"
                :show-password="false"
                type="password"
                placeholder="Senha"
              ></el-input>
            </el-form-item>
          </transition>
        </el-form>
      </el-col>
      <el-col :xl="4" :lg="4" :md="4" :sm="4" :xs="24">
        <el-button
          v-loading="loading"
          v-if="!login"
          :disabled="!nextStep"
          type="success"
          @click="emitKnowAndEmailEvents"
        >Próxima etapa</el-button>
        <el-button
          v-loading="loading"
          v-else
          type="danger"
          class="btn-wrapper"
          @click="handleLogin"
        >Autenticar</el-button>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  data() {
    const checkEmail = async (rule, value, callback) => {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.login = false;
        this.nextStep = false;
        return callback(new Error("Por favor insira um email válido"));
      } else {
        this.loading = true;
        try {
          await this.resetEmailGetter();
          await this.checkEmailAlreadyExist(value);
          if (!this.user.registered) {
            this.login = false;
            this.nextStep = true;
            this.loading = false;

            callback();
          } else {
            this.login = true;
            this.nextStep = false;
            this.loading = false;

            return callback();
          }
        } catch (error) {}
      }
    };
    return {
      loading: false,
      nextStep: false,
      login: false,
      emailForm: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          {
            required: true,
            trigger: "change",
            validator: checkEmail
          }
        ],
        password: [
          {
            required: true,
            trigger: "change",
            min: 8,
            message: "A senha é obrigatória !"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      user: types.USER_INFO_BY_EMAIL
    })
  },
  methods: {
    emitKnowAndEmailEvents() {
      this.$emit("knowEvent");
      this.$emit("userEmailEvent", this.emailForm.email);
    },
    async checkEmailAlreadyExist(value) {
      try {
        await this.$store.dispatch(types.USER_INFO_BY_EMAIL, { email: value });
        await this.$store.dispatch(types.STORE_CATEGORIES);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      if (this.user.registered) {
        this.login = true;
      }
    },
    async resetEmailGetter() {
      await this.$store.dispatch(types.USER_INFO_BY_EMAIL_RESET);
    },
    async handleLogin() {
      const data = {
        email: this.emailForm.email,
        password: this.emailForm.password
      };
      try {
        await this.$store.dispatch(types.AUTH_LOGIN, data);
        this.$notify.success("Autenticado com sucesso !");
        this.$emit("knowEvent");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$store.dispatch(types.AUTH_LOGOUT);
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.component--wrapper {
  max-width: 700px;
  margin: 1rem auto 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
}
.input--wrapper {
  margin-top: 2rem;
}
.typewriter {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 1.5s steps(40, end);
  @media screen and(max-width: 767px) {
    font-size: 0.7rem;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.login-fade-enter-active {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.2s;
}
.login-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.login-fade-leave-to {
  transform: translateY(30%);
  opacity: 0;
}
.login-fade-enter {
  transform: translateY(-30%);
  opacity: 0;
}
</style>
